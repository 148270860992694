import axios from 'axios';

const defaultPositions = {
  'towels': 'tl',
  'robes': 'rf',
  'kilts': 'kp'
};

import { BASE_URL_HILZWEB } from './ConstantService';

export default {
  fetchStitching(hash) {
    return axios.get(`${BASE_URL_HILZWEB}stichings/stichings/${hash}`)
  },

  updateStitching(stitching) {
    return axios({
      method: 'post',
      url: `${BASE_URL_HILZWEB}stichings/update/${stitching.id}/${stitching.hash}`,
      data: this.objectToFormData(stitching),
      config: { headers: { 'Content-Type': 'multipart/form-data' }}
    })
      .then(response => response.data.data);
  },

  createStitching(stitching) {
    return axios({
      method: 'post',
      url: `${BASE_URL_HILZWEB}stichings/create`,
      data: this.objectToFormData(stitching),
      config: { headers: { 'content-type': 'multipart/form-data' }}
    })
      .then(response => response.data.data);
  },

  getInitialStitching({ sessionId, itemId, rowId, configType }) {
    return {
      id: null,
      hash: null,
      quantity: 1,
      plentyId: sessionId,
      itemId: itemId,
      basketRowId: rowId,
      position: defaultPositions[configType],
      type: 'name',
      font: 'ballantines-script',
      size: '20',
      color: '#3B274A',
      text: 'Max Muster',
      status: 'draft',
      price: 0.0,
    };
  },

  objectToFormData(objcet) {
    const formData = new FormData();

    for (const key in objcet) {
      formData.append(key, objcet[key]);
    }

    return formData;
  }
}


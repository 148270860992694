<style scoped lang="scss">
  .preview-wrapper {
    @media only screen and (max-width: 599px) {
      display: none;
    }

    h3 {
      margin: 12px 0;
    }

    .preview-container {
      $padding: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #efefef;
      height: calc(100% - #{4*$padding});
      border-radius: 4px;

      .preview-image {
        width: 135px;
        height: 200px;
        position: relative;

        .position-button {
          position: absolute;
          width: 24px;
          height: 24px;
          text-decoration: none;
          outline: none;
        }
      }
    }
  }
</style>

<template>
  <div class="preview-wrapper">
    <h3 class="font-weight-medium">Position</h3>

    <div class="preview-container">
      <div class="preview-image" :style="previewCss()">
        <div class="button-wrapper" v-if="configType === 'towels'">
          <button v-for="previewPosition in towelPreviewPositions" :key="previewPosition" class="position-button"
                  v-on:click="stitchingConfig.position = previewPosition" v-on:change="updateOrCreateStitchingCallback()"
                  :style="{ left: previewPositions[previewPosition].left, top: previewPositions[previewPosition].top }">
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data: () => ({
      previewPositions: {
        'tl': { offset: 0, left: '24px', top: '32px', towel: true, },
        'tc': { offset: -135, left: '56px', top: '32px', towel: true, },
        'tr': { offset: -270, left: '92px', top: '32px', towel: true, },
        'bl': { offset: -405, left: '24px', top: '142px', towel: true, },
        'bc': { offset: -540, left: '56px', top: '142px', towel: true, },
        'br': { offset: -675, left: '92px', top: '142px', towel: true, },
        'kp': { offset: -810, left: '24px', top: '32px', towel: false, },
        'rf': { offset: -945, left: '24px', top: '32px', towel: false, },
        'rb': { offset: -1080, left: '24px', top: '32px', towel: false, },
      }
    }),
    computed: {
      towelPreviewPositions() {
        return Object.keys(this.previewPositions).filter(key => this.previewPositions[key].towel)
      },
    },
    methods: {
      previewCss() {
        return {
          'background-position': this.previewPositions[this.stitchingConfig.position].offset + 'px',
          'background-image': 'url(' + require('./../assets/preview-sprite.png') + ')'
        };
      }
    },
    props: ['stitchingConfig', 'configType', 'updateOrCreateStitchingCallback'],
  }
</script>

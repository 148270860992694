import axios from 'axios';
import lodash from 'lodash'

import { BASE_URL_HILZWEB, BASE_URL_HANDTUCHSHOP } from './ConstantService';

export default {
  fetchBasketItem(itemId, rowId) {
    return axios
      .get(`${BASE_URL_HILZWEB}items/get-basket-row-info/${itemId}/${rowId}`)
      .then(response => response.data.data);
  },

  fetchBasketItemById(id) {
    return axios
      .get(`${BASE_URL_HANDTUCHSHOP}rest/basket/items/${id}`, { withCredentials: true })
      .then(response => response.data);
  },

  fetchIoBasketItemById(id) {
    return axios
      .get(`${BASE_URL_HANDTUCHSHOP}rest/io/basket/items/${id}`, { withCredentials: true })
      .then(response => response.data);
  },

  fetchBasketItems() {
    return axios
      .get(`${BASE_URL_HANDTUCHSHOP}rest/io/basket/items`, { withCredentials: true })
      .then(response => response.data);
  },

  removeStitchingFromBasket(canceledStitchingConfig, $store) {
    return this.fetchBasketItems()
      .then(({ data }) => {
        const basketItemToRemove = lodash.find(data, basketItem => lodash.some(basketItem.basketItemOrderParams, {value: canceledStitchingConfig.hash}));
        this.removeBasketItem(basketItemToRemove, $store);
      })
  },

  addStitchingToBasket(stitchingConfig, $store) {
    const basketObject =
      {
        variationId             :   this.getVariationIdFromStitchingConfig(stitchingConfig),
        quantity                :   parseInt(stitchingConfig.quantity),
        basketItemOrderParams   :   [
          {
            property: {
              id   : "154",
              valueType: "text",
              value: stitchingConfig.hash,
              names: {
                name: "Bestickungsnummer"
              },
            }
          }
        ]
      };

    return $store.dispatch("addBasketItem", basketObject).then(
      response =>
      {

      },
      error =>
      {
        this.waiting = false;

        if (error.data)
        {
          NotificationService.error(
            TranslationService.translate(
              "Ceres::Template." + ExceptionMap.get(error.data.exceptionCode.toString())
            )
          ).closeAfter(5000);
        }
      });
  },

  removeBasketItem(basketItem, $store) {

    return this.fetchBasketItemById(basketItem.id).then((response) => {
      if (!response.id) {
        console.log("basket item already removed");
        return Promise.resolve();
      }
      return $store.dispatch("removeBasketItem", basketItem.id).then(
        response =>
        {
          document.dispatchEvent(new CustomEvent("afterBasketItemRemoved", { detail: basketItem }));
        })
        .catch(error => {

        });
    })
  },

  changeBasketItemQuantity(basketItem, quantity, $store) {

    return $store.dispatch("updateBasketItemQuantity", { basketItem: basketItem, quantity: quantity }).then(
      response =>
      {
        document.dispatchEvent(new CustomEvent("afterBasketItemQuantityUpdated", { detail: this.basketItem }));

      },
      error =>
      {

      });
  },

  getTextLengthGroup(text) {
    if (text.length <= 11) {
      return 11;
    } else if (text.length <= 16) {
      return 16;
    } else if (text.length <= 21) {
      return 21;
    }
  },

  getPriceIdFromStitchingConfig(stitchingConfig) {
    let itemConfigKey;
    if (stitchingConfig.type === 'name') {
      itemConfigKey = stitchingConfig.size + '-' + this.getTextLengthGroup(stitchingConfig.text);
    } else {
      itemConfigKey = stitchingConfig.size;
    }

    const configsVariationPriceIDMap = {
      name: {
        '20-11': '3326',
        '20-16': '3329',
        '20-21': '3332',
        '25-11': '3327',
        '25-16': '3330',
        '25-21': '3333',
        '30-11': '3328',
        '30-16': '3331',
        '30-21': '3334'
      },
      mono: {
        '30': '3323',
        '40': '3324',
        '50': '3325'
      }
    };

    return configsVariationPriceIDMap[stitchingConfig.type][itemConfigKey];
  },

  getVariationConfigFromStitchingConfig(stitchingConfig) {
    let itemConfigKey;
    if (stitchingConfig.type === 'name') {
      itemConfigKey = stitchingConfig.size + '-' + this.getTextLengthGroup(stitchingConfig.text);
    } else {
      itemConfigKey = stitchingConfig.size;
    }

    const configsVariationConfigMap = {
      name: {
        '20-11': {'167': '536', '166': '530'},
        '20-16': {'167': '537', '166': '530'},
        '20-21': {'167': '538', '166': '530'},
        '25-11': {'167': '536', '166': '531'},
        '25-16': {'167': '537', '166': '531'},
        '25-21': {'167': '538', '166': '531'},
        '30-11': {'167': '536', '166': '532'},
        '30-16': {'167': '537', '166': '532'},
        '30-21': {'167': '538', '166': '532'}
      },
      mono: {
        '30': {'166': '533'},
        '40': {'166': '534'},
        '50': {'166': '535'}
      }
    };

    return configsVariationConfigMap[stitchingConfig.type][itemConfigKey];
  },


  getVariationIdFromStitchingConfig(stitchingConfig) {
    let itemConfigKey;
    if (stitchingConfig.type === 'name') {
      itemConfigKey = stitchingConfig.size + '-' + this.getTextLengthGroup(stitchingConfig.text);
    } else {
      itemConfigKey = stitchingConfig.size;
    }

    const configsVariationIdMap = {
      name: {
        '20-11': '3326',
        '20-16': '3329',
        '20-21': '3332',
        '25-11': '3327',
        '25-16': '3330',
        '25-21': '3333',
        '30-11': '3328',
        '30-16': '3331',
        '30-21': '3334'
      },
      mono: {
        '30': '3323',
        '40': '3324',
        '50': '3325'
      }
    };

    return configsVariationIdMap[stitchingConfig.type][itemConfigKey];
  },

  getStichingItemIdFromStitchingConfig(stitchingConfig) {
    if (stitchingConfig.type === 'name') {
      return "2000720337";
    } else {
      return "2000720336";
    }
  },
}


<style lang="scss">
  .application--wrap {
    background: white;
    min-height: auto !important;
    max-width: min-content;
  }

  .theme--light.application {
    background: #fff !important;
  }

  label {
    display: initial;
    margin-bottom: initial;
  }

  hr {
    margin-top: initial;
    margin-bottom: inherit;
  }

  .v-dialog {
    max-width: 1300px;
  }

  .v--modal-overlay {
    z-index: 1000 !important;
  }

  @font-face {
    font-family: "casta";
    src: url('./fonts/casta.ttf');
  }

  @font-face {
    font-family: "ballantines-script";
    src: url('./fonts/ballantines-script.ttf');
  }

  @font-face {
    font-family: "book-script";
    src: url('./fonts/book-script.ttf');
  }

  @font-face {
    font-family: "chancery";
    src: url('./fonts/chancery.ttf');
  }

  @font-face {
    font-family: "columbo";
    src: url('./fonts/columbo.ttf');
  }

  @font-face {
    font-family: "flash";
    src: url('fonts/flash.ttf');
  }

  @font-face {
    font-family: "hobo";
    src: url('./fonts/hobo.ttf');
  }

  @font-face {
    font-family: "lazer";
    src: url('./fonts/lazer.ttf');
  }

  @font-face {
    font-family: "orient-express";
    src: url('./fonts/orient-express.ttf');
  }

  @font-face {
    font-family: "round-block";
    src: url('./fonts/round-block.ttf');
  }

  @font-face {
    font-family: "poetic-script";
    src: url('./fonts/poetic-script.ttf');
  }

  @font-face {
    font-family: "woodstock";
    src: url('./fonts/woodstock.ttf');
  }
</style>

<template>
  <v-app light>
  <div>
    <modal width="90%" height="auto" scrollable :name="'modal-' + basketItemId" :clickToClose="false" v-if="basketItem && basketItem.configPossible" >
        <div is="StitchingSettings" v-if="dialog"
             :basketItemName="basketItemName" :rowId="basketItemId" :itemId="itemId"
             :basketItem="basketItem"
             @close="closeDialog"
        ></div>
    </modal>
    <v-btn color="green" dark depressed @click="showDialog" v-if="basketItem && basketItem.configPossible">
      Bestickungen {{  basketItem.configs.length ? 'ändern' : 'hinzufügen' }}
    </v-btn>

    <!--
    <v-dialog light width="500" height="100" v-model="dialog" v-if="basketItem && basketItem.configPossible">
      <v-btn slot="activator" color="green" dark depressed>
        Bestickungen {{  basketItem.configs.length ? 'ändern' : 'hinzufügen' }}
      </v-btn>

      <template v-slot:activator="{ on }">
        <v-btn
          color="red lighten-2"
          dark
          v-on="on"
        >
          Click Me
        </v-btn>
      </template>

      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Privacy Policy
        </v-card-title>

        <v-card-text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            flat
            @click="dialog = false"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>-->
    <!--
    -->
  </div>
  </v-app>
</template>

<script>
  /* eslint-disable no-console */

  import StitchingSettings from './components/StitchingSettings'
  import BasketItemService from "./components/BasketItemService";
  import StichingService from './components/StitchingService';
  import { cloneDeep, find } from 'lodash';

  export default {
    name: 'VueHilzStitching',
    components: {
      StitchingSettings,
    },
    data () {
      return {
        dialog: false,
        basketItem: null,
        itemId: null,
        stitchingConfig: null,
        configItemIds: [2000720337, 2000720336],
        optionsMap: {
          'bis 11 Zeichen': 11,
          'bis 16 Zeichen': 16,
          'bis 21 Zeichen': 21,
          'Monogramm 30mm': 30,
          'Monogramm 40mm': 40,
          'Monogramm 50mm': 50,
          '20mm': 20,
          '25mm': 25,
          '30mm': 30
        },
        attributeValueIdMap: {
          // sizes
          530: 20,
          531: 25,
          532: 30,
          533: 30,
          534: 40,
          535: 50,

          // lengths
          536: 11,
          537: 16,
          538: 21,
        }
      }
    },
    mounted() {
      const deleteBtn = document.getElementById('hw-basket-delete-' + this.basketItemId);
      deleteBtn.addEventListener('click',async  () => {
        if (this.isStichingItem) {
          StichingService.updateStitching({...this.stitchingConfig, status: 'cancelled'}).then(() => {
            window.vueEventHub.$emit('hw::stiching::refresh');
          })
        } else {
          for (let i = 0; i < this.basketItem.configs.length; i++) {
            let config = this.basketItem.configs[i];
            console.log("cancel stiching item " + config);
            const canceledStitchingConfig = await StichingService.updateStitching({...config, status: 'cancelled'});
            console.log("remove stiching basket item " + config);
            await BasketItemService.removeStitchingFromBasket(canceledStitchingConfig, this.$store);
            console.log("removed stiching basket item " + config);
          }
        }
      })
    },
    watch: {
      'plentyBasketItem.quantity': {
        handler: function(newValue) {
          this.basketItem.quantity = newValue;
        }
      }
    },
    computed: {
      isStichingItem() {
        return this.configItemIds.indexOf(this.plentyBasketItem.itemId) !== -1
      }
    },
    methods: {
      cloneDeep(arg) {
        debugger
        return cloneDeep(arg);
      },

      showDialog() {
        this.$modal.show('modal-' + this.basketItemId)
        this.dialog = true;
      },

      closeDialog() {
        this.$modal.hide('modal-' + this.basketItemId)
        this.dialog = false;
        window.vueEventHub.$emit('hw::stiching::refresh');
        // this.fetchBasketItemById(this.basketItemId);
        // location.reload();
      },
      fetchBasketItemById(basketItemId) {
        if (basketItemId === undefined) {
          return;
        }
        /*
        BasketItemService.fetchBasketItem(2000720318, basketItemId)
          .then(basketItem => {
            this.basketItem = basketItem;
            console.log(basketItem);
            this.basketItem.quantity = 3;
            this.basketItem.configs.forEach(config => config.size = config.size.toString());
          })
          .catch(errors => console.error('Could not fetch basket item by id and row id', errors));
        */

          this.itemId = this.plentyBasketItem.itemId;
          BasketItemService.fetchBasketItem(this.itemId, basketItemId)
            .then(basketItem => {
              this.basketItem = basketItem;

              if (basketItem.configPossible) {
                this.basketItem.quantity = this.plentyBasketItem.quantity;
                this.basketItem.configs.forEach(config => {
                  config.size = config.size.toString()
                });
              }

              if (this.isStichingItem) {
                const stichingHash = find(this.plentyBasketItem.basketItemOrderParams, { propertyId: "154" }).value;

                if (stichingHash === undefined) {
                  console.log("stiching item with no stiching hash detected - remove basket item " + basketItemId);
                  BasketItemService.removeBasketItem(this.plentyBasketItem, this.$store);
                  return;
                }

                StichingService.fetchStitching(stichingHash).then(stitchingConfig => {
                  this.stitchingConfig = stitchingConfig.data.data;

                  if (this.stitchingConfig.status === 'cancelled') {
                    // if external config is cancelled, remove the item from the basket
                    console.log('external config cancelled, delete basket item ' + basketItemId);
                    // BasketItemService.removeStitchingFromBasket(this.stitchingConfig);
                    //plenty.BasketService.removeItem(basketItemId, true);
                    BasketItemService.removeBasketItem(this.plentyBasketItem, this.$store);
                    // document.getElementById('hw-basket-delete-' + basketItemId).click();
                    return;
                  }

                  const getTextLengthGroup = function(text) {
                    if (text.length <= 11) {
                      return 11;
                    } else if (text.length <= 16) {
                      return 16;
                    } else if (text.length <= 21) {
                      return 21;
                    }
                  };

                  // check whether the stiching item matches the associated configuration
                  let configConfigKey;
                  if (this.stitchingConfig.type === 'name') {
                    configConfigKey = this.stitchingConfig.size + '-' + getTextLengthGroup(this.stitchingConfig.text);
                  } else {
                    configConfigKey = this.stitchingConfig.size;
                  }

                  let itemConfigKey;
                  const stichingSizeValueId =
                    jQuery('.data-basket-item-param[data-basket-item-id="'+basketItemId+'"][data-item-param-id="166"]').attr('data-item-param-value-id');
                  if (basketItem.id === 2000720337) {
                    const stichingLengthValueId =
                      jQuery('.data-basket-item-param[data-basket-item-id="'+basketItemId+'"][data-item-param-id="167"]').attr('data-item-param-value-id');
                    itemConfigKey = this.attributeValueIdMap[stichingSizeValueId] + '-' + this.attributeValueIdMap[stichingLengthValueId]
                  } else {
                    itemConfigKey = this.attributeValueIdMap[stichingSizeValueId];
                  }

                  if (configConfigKey !== itemConfigKey) {
                    console.log('wrong stiching item', configConfigKey, itemConfigKey);
                    BasketItemService.addStitchingToBasket(this.stitchingConfig, this.$store);
                    //document.getElementById('hw-basket-delete-' + basketItemId).click();
                    BasketItemService.removeBasketItem(this.plentyBasketItem, this.$store).then(() => {
                      window.vueEventHub.$emit('hw::stiching::refresh');
                    })
                    return
                  }

                  const basketItemTitle = document.getElementById('basket-item-title-' + basketItemId);
                  BasketItemService.fetchIoBasketItemById(this.stitchingConfig.basketRowId).then(({data: { variation: { data: { texts: { name1 }} }}}) => {
                    basketItemTitle.innerHTML = this.plentyBasketItem.variation.data.texts.name1 + ' "' + this.stitchingConfig.text +
                      '" zu Artikel "' + name1 + '"';
                  })


                  if (this.plentyBasketItem !== this.stitchingConfig.quantity) {
                    BasketItemService.changeBasketItemQuantity(this.plentyBasketItem,this.stitchingConfig.quantity, this.$store)
                  }

                  const quantityContainer = document.getElementById('quantity-input-wrapper-' + basketItemId);
                  quantityContainer.innerHTML = this.plentyBasketItem.quantity + ' Stück';
                });
              }
            })
            .catch(errors => console.error('Could not fetch basket item by id and row id', errors));
        },

        refresh() {
          this.fetchBasketItemById(this.basketItemId);
        }
      },
      created() {
        this.fetchBasketItemById(this.basketItemId);
        window.vueEventHub.$on('hw::stiching::refresh', this.refresh);
      },
      beforeDestroy() {
        window.vueEventHub.$off('hw::stiching::refresh', this.refresh);
      },
      props: ['basketItemId', 'basketItemName', 'plentyBasketItem'],
    }
</script>

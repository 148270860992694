<style scoped lang="scss">
  .preview-wrapper {
    h3 {
      margin-bottom: 12px;
      margin-top: 0;
    }

    .preview {
      height: 120px;
      border-radius: 4px;
      border: gray dotted;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      label {
        font-size: 2rem;
        color: inherit;
      }

      &.minimal {
        height: 100px;
      }
    }
  }
</style>

<template>
  <div class="preview-wrapper">
    <h3 class="font-weight-medium" v-if="!isMinimal">Vorschau</h3>

    <div class="preview" :class="{ minimal: isMinimal }" :style="getStyles(stitchingConfig)">
      <label :style="getFontStyle(stitchingConfig)">{{ stitchingConfig.text }}</label>
    </div>
  </div>
</template>

<script>
  export default {
    data: () => ({
      brightColors: ['#E2D8E1', '#FFFFFF', '#E6CAAA'],
    }),
    methods: {
      getFontStyle(stitchingConfig) {
        return { 'font-family': stitchingConfig.font };
      },
      getStyles(stitchingConfig) {
        return {
          color: stitchingConfig.color,
          'background-color': this.brightColors.includes(stitchingConfig.color) ? '#999' : '#fff',
        }
      }
    },
    props: ['stitchingConfig', 'isMinimal'],
  }
</script>

<style scoped lang="scss">
  .headline strong {
    width: 100%;
  }

  .config-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media only screen and (min-width: 600px) {
      flex-direction: row;
    }

    .config-selection-wrapper {
      display: flex;
      flex-direction: column;
      padding: 16px;

      @media only screen and (max-width: 599px) {
        order: 2;
      }

      label {
        margin-bottom: 14px;
        font-size: 24px;
      }

      .existing-config {
        display: flex;
        flex-direction: row;
        padding-left: 12px;
        padding-top: 4px;
        cursor: pointer;
        justify-content: space-between;
        margin-bottom: 16px;
        border-radius: 4px;

        .config-attributes {
          width: 90%;
        }

        .button-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .delete-config {
            min-width: auto;
          }
        }

        &.selected {
          border-left: 4px solid green;
          padding-left: 8px;
          background-color: #dedede;
        }

        span {
          text-align: center;
        }

        .v-btn--small {
          margin: 0;
          width: 20px;
          height: 20px;
        }
      }
    }

    .no-config-container {
      width: 65%;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
    }

    .settings-container {
      padding: 16px;

      label {
        margin-top: 0;
        margin-bottom: 24px;
        text-align: center;
        font-size: 24px;
      }

      .settings-wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @media only screen and (min-width: 600px) {
          flex-direction: row;
        }

        .left-wrapper {
          display: flex;
          flex: 1;
          flex-direction: column;
          padding-right: 8px;
          order: 1;

          @media only screen and (min-width: 600px) {
            order: 0;
          }
        }

        .right-wrapper {
          display: flex;
          flex: 1;
          flex-direction: column;
          padding-left: 8px;
          order: 0;

          @media only screen and (min-width: 600px) {
            order: 1;
          }
        }
      }
    }
  }

  .action-wrapper {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 600px) {
      flex-direction: row;
    }

    h3 {
      margin-right: 32px;
    }

    .price-container, .status-container {
      color: green;
    }

    div.attention {
      display: flex;
      justify-content: center;
      text-align: center;
      width: auto;
      background: #ddd;
      padding: 8px;
      margin: 8px;
      border-radius: 4px;
      align-items: center;

      i {
        color: orange;
        padding-right: 8px;
      }
    }

    .button-wrapper {
      display: flex;
      flex-direction: column;

      .top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 8px;
        flex-direction: column;

        @media only screen and (min-width: 600px) {
          flex-direction: row;
        }
      }
    }
  }
</style>

<template>
    <v-card>
     <v-card-title class="headline" primary-title>
        <small>Sie konfigurieren folgenden Artikel:</small>
        <br/>
        <strong>{{ basketItemName }}</strong>

        <strong style="color:#b9000f;font-size: 20px;padding-top: 16px;" v-if="alert.length > 0">
          {{ alert }}
        </strong>
     </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="config-wrapper">
        <div class="config-selection-wrapper">
          <label class="font-weight-medium">Bestickungen</label>
          <p>Da die Anzahl des ausgewählten Artikels <strong>{{basketItem.quantity}}</strong> beträgt, können Sie bis zu <strong>{{basketItem.quantity}}</strong> Bestickungen hinzufügen.</p>

          <div v-for="(config, index) in basketItem.configs" v-bind:key="config.hash" class="existing-config"
               :class="{ selected: (config && config.hash && stitchingConfig && stitchingConfig.hash) ? config.hash === stitchingConfig.hash : config === stitchingConfig }">
            <div class="config-attributes" v-on:click="selectStitchingConfig(config, index)">
              <StickingPreview :stitchingConfig="config" :isMinimal="true"></StickingPreview>
              <span>Status: <strong>{{ statuses[config.status] }}</strong></span><br/>
              <span>Preis / Bestickung: <strong>{{ getEuroPrice(config.price) }}</strong></span><br/>
              <span>Anzahl: <strong>{{ config.quantity }}</strong></span>
            </div>

            <div class="button-wrapper">
              <v-btn fab dark small color="red" flat class="delete-config"
                     v-on:click="removeStitching(basketItem, index, config)">
                <v-icon dark>delete</v-icon>
              </v-btn>

              <v-btn fab small flat v-on:click="selectStitchingConfig(config, index)">
                <v-icon dark>chevron_right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>

        <v-divider :vertical="true"></v-divider>

        <div v-if="!stitchingConfig" class="no-config-container">
          <label>Keine Bestickung ausgwählt</label>
          <h3>Wählen Sie eine Bestickung aus Liste links aus, oder fügen Sie eine neue hinzu.</h3>
        </div>

        <div class="settings-container" v-if="stitchingConfig">
          <label class="font-weight-medium">Bestickungs Konfiguration</label>

          <div class="settings-wrapper">
            <div class="left-wrapper">
              <PositionPreview class="position-preview" :stitchingConfig="stitchingConfig"
                               :configType="basketItem.configType"
                               :updateOrCreateStitchingCallback="updateOrCreateStitching">
              </PositionPreview>
              <StickingPreview class="sticking-preview" :stitchingConfig="stitchingConfig"></StickingPreview>
            </div>

            <stitchingConfiguration class="right-wrapper" :stitchingConfig="stitchingConfig"
                                    :configType="basketItem.configType"
                                    :updateOrCreateStitchingCallback="updateOrCreateStitching"
                                    :calculateAvailableQuantity="calculateAvailableQuantity.bind(this, basketItem)">
            </stitchingConfiguration>
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="action-wrapper">
        <h3>
          <label class="price-container">{{ getAccumulatedPrice(basketItem) }}</label>
          <br/>
          Gesamtpreis inkl. MwSt.
        </h3>

        <v-spacer></v-spacer>

        <div class="attention">
          <v-icon>warning</v-icon>
          Achtung, für Bestickungen benötigen wir eine Vorlaufzeit von 3 bis 8 Werktagen<br/>
        </div>

        <v-spacer></v-spacer>

        <div class="button-wrapper">
          <div class="top">
            <v-btn depressed @click="$emit('close')">Abbrechen</v-btn>
            <v-btn v-if="calculateAvailableQuantity(basketItem) >= 1" color="blue" dark
                   @click="addStitching(basketItem)">
              Nächste Bestickung
            </v-btn>
            <v-btn v-if="calculateAvailableQuantity(basketItem) < 1" color="green" dark depressed
                   @click="saveStitchingSettings(stitchingConfig)">
              Speichern und zurück zum Warenkorb
            </v-btn>
          </div>

          <v-btn v-if="calculateAvailableQuantity(basketItem) >= 1" color="green" dark depressed
                 @click="saveStitchingSettings(stitchingConfig)">
            Speichern und zurück zum Warenkorb
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>

</template>

<script>
  import axios from 'axios';

  import { BASE_URL_HILZWEB } from './ConstantService';

  /* eslint-disable no-console */
  import PositionPreview from './PositionPreview';
  import StickingPreview from './StitchingPreview';
  import stitchingConfiguration from './StitchingConfiguration';

  import StickingService from './StitchingService';
  import BasketItemService from "./BasketItemService";

  export default {
    name: "StitchingSettings",
    components: {
      PositionPreview,
      StickingPreview,
      stitchingConfiguration,
    },
    data: () => ({
      stitchingConfig: null,
      currentSelectedConfigIndex: null,
      alert: '',
      statuses: {
        draft: 'Entwurf',
        basket: 'Einkaufswagen',
      },
    }),
    methods: {
      calculateAvailableQuantity(basketItem) {
        return basketItem.quantity - basketItem.configs.reduce((sum, config) => sum + Number(config.quantity), 0);
      },
      updateOrCreateStitching() {
        if (this.stitchingConfig.id) {
          StickingService.updateStitching(this.stitchingConfig)
            .then(response => {
              this.stitchingConfig = response;
              this.basketItem.configs[this.currentSelectedConfigIndex] = this.stitchingConfig;
            })
        } else {
          StickingService.createStitching(this.stitchingConfig)
            .then(response => {
              this.stitchingConfig = response;
              this.basketItem.configs[this.currentSelectedConfigIndex] = this.stitchingConfig;
            })
            .catch(errors => console.error('Could not create Stitching', errors));
        }
      },
      saveStitchingSettings() {
        const updatePromises = this.basketItem.configs.map(stitchingConfig => {
          const formerStatus = stitchingConfig.status;
          stitchingConfig.status = 'basket';
          return StickingService.updateStitching(stitchingConfig)
            .then(savedStitchingConfig => {
              if (formerStatus === 'draft') {
                return BasketItemService.addStitchingToBasket(savedStitchingConfig, this.$store)
                  .catch(errors => console.error('Could not add Stitching to Basket', errors));
              }
            })
            .catch(errors => console.error('Could not save Stitching', errors));
        });

        if (updatePromises.length > 0) {
          return Promise
            .all(updatePromises)
            .then(() => { this.$emit('close') })
        } else {
          this.$emit('close')
        }
      },
      getAccumulatedPrice(basketItem) {
        return this.getEuroPrice(basketItem.configs.reduce((sum, config) => sum + Number(config.price) * config.quantity, 0));
      },
      getEuroPrice(price) {
        return '€ ' + parseFloat(price).toFixed(2).replace('.', ',');
      },
      selectStitchingConfig(config, index) {
        this.stitchingConfig = config;
        this.currentSelectedConfigIndex = index;
        this.updateOrCreateStitching();
      },

      getCookie(name) {
        const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) return match[2];
      },

      addStitching(basketItem) {
        const newConfig = this.getPrototypeStitchingConfig(basketItem);
        newConfig.hash = null;
        newConfig.id = null;
        newConfig.text = 'Max Muster';
        newConfig.quantity = 1;
        newConfig.plentyId = this.getCookie('plentyID');
        basketItem.configs.push(newConfig);

        this.selectStitchingConfig(newConfig, basketItem.configs.length - 1);
      },
      removeStitching(basketItem, configIndex, stitchingConfig) {
        const formerStatus = stitchingConfig.status;
        stitchingConfig.status = 'cancelled';
        StickingService.updateStitching(stitchingConfig)
          .then(canceledStitchingConfig => {
            if (formerStatus === 'basket') {
              BasketItemService.removeStitchingFromBasket(canceledStitchingConfig, this.$store)
                .catch(errors => console.error('Could not delete Stitching from basket', errors));
            }

            this.stitchingConfig = null;
            basketItem.configs.splice(configIndex, 1);
            this.selectInitialStitchingConfig(basketItem, true);
          })
          .catch(errors => console.error('Could not cancel Stitching', errors));
      },
      getPrototypeStitchingConfig(basketItem) {
        const initConfig = {
          configType: basketItem.configType,
          rowId: this.rowId,
          itemId: this.itemId,
        };
        return StickingService.getInitialStitching(initConfig);
      },
      selectInitialStitchingConfig(basketItem, doNotAddStitching) {
        if (basketItem.configs.length) {
          this.selectStitchingConfig(basketItem.configs[0], 0);
        } else if (!doNotAddStitching) {
          this.addStitching(basketItem)
        }
      },
    },
    created() {
      this.selectInitialStitchingConfig(this.basketItem);
      axios.get(`${BASE_URL_HILZWEB}stichings/alert`).then(response => {
        this.alert = response.data.data
      })

    },
    props: ['rowId', 'basketItemName', 'itemId', 'basketItem'],
  }
</script>

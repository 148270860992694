<style scoped lang="scss">
  h3 {
    margin-bottom: 12px;
    margin-top: 0;
  }

  .color-button {
    margin: 2px;
    height: 30px;
    width: 30px;
  }

  .button-container {
    border: 2px solid rgba(0,0,0,0.54);
    border-radius: 4px;
    padding: 8px;

    label {
      display: flex;
      font-size: 12px;
      padding-left: 4px;
      padding-bottom: 4px;
      color: rgba(0,0,0,0.54);
    }
  }
</style>

<template>
  <v-form ref="configForm" v-model="isConfigValid" lazy-validation>
    <h3 class="font-weight-medium">Allgemein</h3>

    <v-select v-model="stitchingConfig.type" :items="stitchingTypes" label="Typ" required outline item-text="label"
              item-value="id" v-on:change="onStitchingConfigTypeChange()" attach>
    </v-select>

    <v-text-field v-model="stitchingConfig.text" label="Sticktext" required outline
                  :rules="[value => !!value || 'Pflichtfeld', maxLengthValidation.bind(this, stitchingConfig.text)]" counter
                  :maxlength="getMaxTextLength(stitchingConfig.type, stitchingTypes)">
    </v-text-field>

    <v-select v-model="stitchingConfig.position" :items="positions[configType]" label="Position" required outline
              item-text="title" item-value="value" v-on:change="updateOrCreateStitchingCallback()" attach>
    </v-select>

    <v-select v-model="stitchingConfig.size" :items="sizes[stitchingConfig.type]" label="Schriftgröße" required
              outline item-text="title" item-value="value" v-on:change="updateOrCreateStitchingCallback()" attach>
    </v-select>

    <v-select v-model="stitchingConfig.quantity" :items="getPossibleQuantities()" label="Anzahl" required
              outline item-text="title" item-value="value" v-on:change="updateOrCreateStitchingCallback()" attach>
    </v-select>

    <v-select v-model="stitchingConfig.font" :items="fonts[stitchingConfig.type]" label="Schriftart" required
              outline item-text="title" item-value="value" v-on:change="updateOrCreateStitchingCallback()" attach>
      <template slot="selection" slot-scope="data">
        <span :style="getFont(data.item.value)">{{ data.item.title }}</span>
      </template>
      <template slot="item" slot-scope="data">
        <span :style="getFont(data.item.value)">{{ data.item.title }}</span>
      </template>
    </v-select>

    <div class="button-container">
      <label>Schriftfarbe</label>
      <v-btn v-for="color in colors" :key="color" :color="color" fab small v-on:click="stitchingConfig.color = color"
             class="color-button">
      </v-btn>
    </div>
  </v-form>
</template>

<script>
  export default {
    data: () => ({
      isConfigValid: true,
      statuses: {
        draft: 'Entwurf',
      },
      stitchingTypes: [
        {
          id: 'mono',
          length: 2,
          label: 'Monogramm (bis 2 Zeichen)',
        },
        {
          id: 'name',
          length: 21,
          label: 'Name (bis 21 Zeichen)',
        },
      ],
      colors: [
        '#3B274A', '#31AAD1', '#224099', '#0084B6', '#1B7895', '#840000', '#663E37', '#CB3B1C', '#FBD500', '#FFDE05',
        '#E2D8E1', '#515E63', '#4D4044', '#92D45C', '#32574A', '#AEB256', '#E6CAAA', '#FF831D', '#FF3082', '#E50000',
        '#914AB4', '#340D6B', '#FFFFFF', '#000000',
      ],
      fonts: {
        'mono': [
          { value: 'ballantines-script', title: 'Ballantines Script' },
          { value: 'poetic-script', title: 'Poetic Script' },
          { value: 'woodstock', title: 'Woodstock' },
        ],
        'name': [
          { value: 'ballantines-script', title: 'Ballantines Script' },
          { value: 'book-script', title: 'Book Script' },
          { value: 'casta', title: 'Casta' },
          { value: 'chancery', title: 'Chancery' },
          { value: 'columbo', title: 'Columbo' },
          { value: 'flash', title: 'Flash' },
          { value: 'hobo', title: 'Hobo' },
          { value: 'lazer', title: 'Lazer' },
          { value: 'orient-express', title: 'Orient Express' },
          { value: 'round-block', title: 'Round Block' },
        ]
      },
      sizes: {
        'mono' : [
          { value: '30' , title: '30 Millimeter' },
          { value: '40' , title: '40 Millimeter' },
          { value: '50' , title: '50 Millimeter' },
        ],
        'name' : [
          { value: '20' , title: '20 Millimeter' },
          { value: '25' , title: '25 Millimeter' },
          { value: '30' , title: '30 Millimeter' },
        ]
      },
      positions: {
        'towels': [
          { value: 'tl' , title: 'Oben Links' },
          { value: 'tc' , title: 'Oben Mitte' },
          { value: 'tr' , title: 'Oben Rechts' },
          { value: 'bl' , title: 'Unten Links' },
          { value: 'bc' , title: 'Unten Mitte' },
          { value: 'br' , title: 'Unten Rechts' },
        ],
        'kilts': [{ value: 'kp', title: 'Über Tasche' }],
        'robes': [
          { value: 'rf', title: 'Vorne Brust' },
          { value: 'rb', title: 'Rücken Mitte' },
        ]
      },
    }),
    methods: {
      maxLengthValidation(value) {
        const maxLength = this.stitchingTypes.find(type => type.id === this.stitchingConfig.type).length;

        if (value.length > maxLength) {
          this.stitchingConfig.text = value.substring(0, maxLength);
        }

        // we always return true, as we cut the stitching string, if it is to long
        return true;
      },
      getFont(font) {
        return {
          'font-family': font,
        }
      },
      getMaxTextLength(stitchingType, stitchingTypes) {
        return stitchingType.id && stitchingTypes.find(type => type.id === stitchingType.id).length;
      },
      getPossibleQuantities() {
        // We calculate all possible quantities based on the available quantities and the current quantity
        const maxStitchingQuantity = this.calculateAvailableQuantity() + Number(this.stitchingConfig.quantity);
        return [...Array(maxStitchingQuantity).keys()].map(value => (++value).toString(10)).map(value => ({ title: value, value }));
      },
      onStitchingConfigTypeChange() {
        this.stitchingConfig.size = this.sizes[this.stitchingConfig.type][0].value;

        this.$refs.configForm.validate();

        if (this.isConfigValid) {
          this.updateOrCreateStitchingCallback();
        }
      }
    },
    props: ['stitchingConfig', 'configType', 'updateOrCreateStitchingCallback', 'calculateAvailableQuantity'],
  }
</script>

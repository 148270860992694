import '@mdi/font/css/materialdesignicons.css';

// import Vue from 'vue';
// import Vuetify from './plugins/vuetify';

// import Vuetify from 'vuetify/lib'
// import 'vuetify/src/stylus/app.styl'
import VModal from 'vue-js-modal'

import VueHilzStitching from './App.vue';
import StitchingSettings from './components/StitchingSettings';

// Vue.use(Vuetify);

/*
const AppInstance = Vue.extend(
  {
    render(h) {
      return h(
        App,
        {
          props: {
            basketItemId: this.$el.attributes.basketItemId.value,
            basketItemName: this.$el.attributes.basketItemName.value,
          }
        }
      )
    }
  }
);

const nodes = document.querySelectorAll('.VueHilzStitching');
for (let i = 0; i < nodes.length; ++i) {
  new AppInstance({ el: nodes[i] })
}
*/

// window.Vuetify = Vuetify;

// Use automatically when global Vue instance detected
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.config.productionTip = false;
  window.Vue.config.devtools = true;

  window.Vue.mixin({
    components: {
      VueHilzStitching: VueHilzStitching,
      StitchingSettings: StitchingSettings
    }
  });
  window.Vue.use(VModal);
  console.log("registered hilzweb stiching")
} else {
  console.log("not registered hilzweb stiching");
}
